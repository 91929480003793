import { getToken, setToken, getRefreshToken } from '../AuthenticationUtility/AuthHelper';

 const baseUrl = "https://api.kntopass.com/";
//const baseUrl = "https://localhost:7285/";

let isRefreshing = false; // Flag to check if token is being refreshed
let refreshSubscribers = []; // Array to hold queued API calls
 
// Function to notify all subscribers (i.e., queued API calls) when the token is refreshed
function onTokenRefreshed(newToken) {
    refreshSubscribers.forEach((callback) => callback(newToken));
    refreshSubscribers = [];
}

// Helper function to refresh the token
async function refreshAccessToken(logout) {
    const refresh_token = getRefreshToken(); // Retrieve the refresh token
    if (!refresh_token) {
        logout(); // No refresh token, force logout
        throw new Error('No refresh token available');
    }

    const response = await fetch(`${baseUrl}api/accounts/refresh`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token }),
    });

    if (response.ok) {
        const data = await response.json();
        const newToken = data.data.access_token; // Assuming the API returns a new access token
        const newRefreshToken = data.data.refresh_token; // Assuming refresh token might also be updated
        setToken(newToken, newRefreshToken, true); // Save both tokens
        isRefreshing = false; // Reset the flag after refresh
        onTokenRefreshed(newToken); // Notify all queued API calls
        return newToken;
    } else {
        // Handle refresh token expiration (logout user)
        logout();
        isRefreshing = false; // Reset the flag after refresh failure
        throw new Error('Refresh token expired or invalid');
    }
}

export default {
    async apiBase(url, methodType, logout, bodyObj, skipAuth = false, isFormData = false) {
        let token = getToken(); // Get the token

        // If skipAuth is true (e.g., during login), do not include the Authorization header
        let headers = isFormData
            ? {}
            : {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
              };

        if (!skipAuth && token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        let response = await fetch(baseUrl + url, {
            method: methodType,
            headers: headers,
            body: bodyObj ? (isFormData ? bodyObj : JSON.stringify(bodyObj)) : null,
        });

        // Check if the token is invalid (401 Unauthorized)
        if (response.status === 401 && !skipAuth) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const newToken = await refreshAccessToken(logout); // Try refreshing the token
                    // Retry the original request with the new token
                    headers['Authorization'] = `Bearer ${newToken}`;
                    response = await fetch(baseUrl + url, {
                        method: methodType,
                        headers: headers,
                        body: bodyObj ? (isFormData ? bodyObj : JSON.stringify(bodyObj)) : null,
                    });
                } catch (error) {
                    console.error('Token refresh failed:', error);
                    return { error: 'Unauthorized. Please login again.' }; // Optionally: redirect to login
                }
            } else {
                // Queue the API call while the refresh token request is in progress
                const retryRequest = new Promise((resolve) => {
                    refreshSubscribers.push((newToken) => {
                        headers['Authorization'] = `Bearer ${newToken}`;
                        resolve(fetch(baseUrl + url, {
                            method: methodType,
                            headers: headers,
                            body: bodyObj ? (isFormData ? bodyObj : JSON.stringify(bodyObj)) : null,
                        }));
                    });
                });

                response = await retryRequest;
            }
        }

        try {
            const responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.error('Error parsing JSON response:', error);
            return { error: 'Error parsing response data' };
        }
    },
};
