/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import apiProjectModules from './Apis/ProjectModules';
import { Alert } from 'react-bootstrap';
import PageLoading from './Helpars/PageLoading';
import { useAuth } from './AuthenticationUtility/AuthContext';

const SideMenu = () => {
  const { logout } = useAuth();
  const params = useParams();

  const [modulesList, setModulesList] = useState([]);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [isPageLoading, setPageLoading] = useState(true);
   useEffect(() => {
      
          ListProjectModules();
      
  }, []);
  const ListProjectModules = async () => {

      let response = await apiProjectModules.list(logout,params.hash_id);
      setModulesList(response.records)
      setStatus(response.status)
      setMessage(response.message)
      setPageLoading(false);
  }
  const IconList = () => {
      return (
          <div>
              {modulesList.map((item) => {
                  const IconComponent = Icon[item.icon];
                  return (
                      <>
                          <li key={item.id} className="nav-item mb-1">
                              <Link to={item.url + params.hash_id} className="nav-link align-middle text-light px-0 ">
                                  <IconComponent size={20} /> <span className="ms-1 d-none d-sm-inline">{item.name}</span>
                              </Link>
                          </li>
                      </>
                  );
              })}
          </div>
      );
  };
  return (
    <div>
            {isPageLoading ? (
                <PageLoading />
            ) : (
                < >
                    {status === "success" ?
                        < >
                            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start px-3" id="menu">

                                <IconList />

                            </ul>

                        </ >
                        : <Alert color="secondary" className="text-center">
                            <h5 className="mb-3">{message}</h5>
                        </Alert>
                    }
                </ >
            )}
        </div >
  );
};
export default SideMenu;
