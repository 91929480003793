import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import ApiAccessModules from "../Apis/AccessModules";
import apiReaderApps from "../Apis/ReaderApps";
import HelparAlert from "../Helpars/Alert";
import PageLoading from "../Helpars/PageLoading";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import AccessCard from "../Accesses/Card";
import CustomFieldsCreate from "../CustomFields/Create";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const AccessModuleUpdate = () => {
  const { logout } = useAuth();
  const params = useParams();
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");

  const [customFieldData, setCustomFieldData] = useState([]);

  const [accessModuleData, setAccessModule] = useState({
    title: "",
    description: "",
    checkins_maximum: null,
    expiration_type: "",
    invitation_is_enable: false,
    duration_type: "",
    invitation_maximum: null,
    invitation_expiration_type: "",
    invitation_duration_type: "",
    invitation_date_start_type: "",
    invitation_checkins_maximum: null,
    reader_app_maximum: null,
    access_module_features: null,
    custom_fields: [],
    reader_apps: [],
  });
  const [readersList, setReadersList] = useState([]);
  const [readers, setReaders] = useState([]);
  const [readersChecked, setReadersChecked] = useState([]);
  const [readersCheckErrors, setReadersCheckErrors] = useState("");

  const [invitationLimitations, setInvitationLimitations] = useState("");

  useEffect(() => {
    populateAccessModuleData();
    ListReaderApps();
  }, []);

  const ListReaderApps = async (reader_apps) => {
    let response = await apiReaderApps.list(logout, params.hash_id,"",1,200);
    if(response&&response.records)
    {
    const newReader = [];
    for (let i = 0; i < response.records.length; i++) {
      const reader =
        reader_apps && reader_apps.find((u) => u.id === response.records[i].id);
      if (reader !== undefined && reader !== null) {
        newReader.push(reader);
      }
    }
    if (newReader) {
      const readerIds = newReader.map((item) => item.id);
      setReaders((prevIds) => [...prevIds, ...readerIds]);
      //Add reader to List readers checked to view in access card
      setReadersChecked((prevReaders) => [...prevReaders, ...newReader]);
    }
  
    setReadersList(response.records);
  }
    setStatus(response.status);
    setMessage(response.message);
  
  };
  function handleCheckboxReaderChange(event) {
    const id = parseInt(event.target.value);

    if (
      !accessModuleData.reader_app_maximum ||
      readers.find((u) => u === id) ||
      readers.length < accessModuleData.reader_app_maximum
    ) {
      var updatedList = [...readers];
      if (event.target.checked) {
        updatedList = [...readers, id];
        //Add reader to List readers checked to view in access card
        const reader = readersList.find((item) => item.id === id);
        if (reader !== undefined) {
          const updatedResults = [...readersChecked, reader];
          setReadersChecked(updatedResults);
        }
      } else {
        updatedList.splice(readers.indexOf(id), 1);

        //Remove reader from List readers checked to view in access card
        setReadersChecked((readersChecked) =>
          readersChecked.filter((item) => item.id !== id)
        );
      }
      setReaders(updatedList);
      if (readers !== null || readers.length > 0) {
        setReadersCheckErrors("");
      }
    }
  }
  const populateAccessModuleData = async () => {
    let response = await ApiAccessModules.GetById(
      logout,
      params.hash_id,
      params.id
    );
    setAccessModule({
      title: response.data.title !== null ? response.data.title : "",
      description:
        response.data.description !== null ? response.data.description : "",
      checkins_maximum:
        response.data.checkins_maximum !== null
          ? response.data.checkins_maximum
          : "",
      expiration_type:
        response.data.expiration_type !== null
          ? response.data.expiration_type
          : "",
      duration_type:
        response.data.duration_type !== null ? response.data.duration_type : "",
      invitation_is_enable:
        response.data.invitation_is_enable !== null
          ? response.data.invitation_is_enable
          : "",
      invitation_maximum:
        response.data.invitation_maximum !== null
          ? response.data.invitation_maximum
          : "",
      invitation_expiration_type:
        response.data.invitation_expiration_type !== null
          ? response.data.invitation_expiration_type
          : "",
      invitation_date_start_type:
        response.data.invitation_date_start_type !== null
          ? response.data.invitation_date_start_type
          : "",
      invitation_checkins_maximum:
        response.data.invitation_checkins_maximum !== null
          ? response.data.invitation_checkins_maximum
          : "",
      invitation_duration_type:
        response.data.invitation_duration_type !== null
          ? response.data.invitation_duration_type
          : "",
      reader_app_maximum:
        response.data.reader_app_maximum !== null
          ? response.data.reader_app_maximum
          : "",
      reader_apps: response.data.reader_apps ? response.data.reader_apps : [],
      access_module_features: response.data.access_module_features
        ? response.data.access_module_features
        : null,
      custom_fields: response.data.custom_fields
        ? response.data.custom_fields
        : null,
    });
    if (response.data.invitation_maximum) {
      setInvitationLimitations("limited");
    } else {
      setInvitationLimitations("unlimited");
    }
    console.log(response.data);
    if (response.data.reader_apps) {
      ListReaderApps(response.data.reader_apps);
    }
    if (response.data.custom_fields) {
      setCustomFieldData(response.data.custom_fields);
    }
    setPageLoading(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "invitation_is_enable") {
      setAccessModule({
        ...accessModuleData,
        invitation_is_enable: !accessModuleData.invitation_is_enable,
      });
    } else if (name === "invitation_maximum") {
      const maxCharacterCount = 3;
      if (value.length <= maxCharacterCount && value > 0) {
        setAccessModule({
          ...accessModuleData,
          invitation_maximum: value,
        });
      }
    } else if (name === "invitation_checkins_maximum") {
      if (value <= 2 && value > 0) {
        setAccessModule({
          ...accessModuleData,
          invitation_checkins_maximum: value,
        });
      }
    } else {
      setAccessModule({
        ...accessModuleData,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();

      let AccessModuleObj = {
        title: accessModuleData.title,
        description: accessModuleData.description,
        checkins_maximum: accessModuleData.checkins_maximum
          ? accessModuleData.checkins_maximum
          : null,
        expiration_type: accessModuleData.expiration_type,
        duration_type: accessModuleData.duration_type,
        invitation_is_enable: accessModuleData.invitation_is_enable,
        invitation_maximum: accessModuleData.invitation_maximum
          ? accessModuleData.invitation_maximum
          : null,
        invitation_expiration_type: accessModuleData.invitation_expiration_type,
        invitation_duration_type: accessModuleData.invitation_duration_type,
        invitation_date_start_type: accessModuleData.invitation_date_start_type,
        invitation_checkins_maximum:
          accessModuleData.invitation_checkins_maximum
            ? accessModuleData.invitation_checkins_maximum
            : null,
        access_modules_reader_ids: readers,
        custom_fields: customFieldData,
      };
      let response = await ApiAccessModules.Update(
        logout,
        params.id,
        params.hash_id,
        AccessModuleObj
      );
      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);
    }
    setValidated(true);
    setLoading(false);
  };

  const onChangeInvitationLimitations = (event) => {
    setInvitationLimitations(event.target.value);
  };
  return (
    <LayoutProjects
      rootName="Access Modules"
      currentRootName="Update"
      rootLink={"/business/projects/access-modules/list/" + params.hash_id}
    >
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <Container fluid="md">
          <Row>
            <Col md={8}>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="w-100"
              >
                <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                  <Card.Header className="bg-secondary text-white">
                    Access Module info
                  </Card.Header>
                  <Card.Body className="d-flex">
                    <Form className="w-100">
                      <HelparAlert
                        status={status}
                        message={message}
                        errors={errors}
                      ></HelparAlert>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Title
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            name="title"
                            value={accessModuleData.title}
                            onChange={handleInputChange}
                            placeholder="Enter name"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            name is required
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Description
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            name="description"
                            value={accessModuleData.description}
                            onChange={handleInputChange}
                            placeholder="Enter Description"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Description is required
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Card>
                <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                  <Card.Header className="bg-secondary text-white">
                    Access setting
                  </Card.Header>
                  <Card.Body className="d-flex p-3">
                    <Form noValidate validated={validated} className="w-100">
                      <Form.Group className="mb-3">
                        <Form.Label>Expiration Type</Form.Label>
                        {accessModuleData.access_module_features &&
                        accessModuleData.access_module_features
                          .is_edit_expiration_type ? (
                          <div>
                            <Form.Check
                              inline
                              name="expiration_type"
                              label="Date"
                              type="radio"
                              checked={
                                accessModuleData.expiration_type === "date"
                              }
                              value="date"
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Check
                              inline
                              name="expiration_type"
                              label="ForEver"
                              type="radio"
                              checked={
                                accessModuleData.expiration_type === "for_ever"
                              }
                              value="for_ever"
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Expiration Type is required
                            </Form.Control.Feedback>
                          </div>
                        ) : (
                          <div className="fw-bold">
                            {accessModuleData.expiration_type}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Duration Type</Form.Label>
                        {accessModuleData.access_module_features &&
                        accessModuleData.access_module_features
                          .is_edit_duration_type ? (
                          <div>
                            <Form.Check
                              inline
                              name="duration_type"
                              label="Custom"
                              type="radio"
                              checked={
                                accessModuleData.duration_type === "custom"
                              }
                              value="custom"
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Check
                              inline
                              name="duration_type"
                              label="Day"
                              type="radio"
                              checked={accessModuleData.duration_type === "day"}
                              value="day"
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Check
                              inline
                              name="duration_type"
                              label="Week"
                              type="radio"
                              checked={
                                accessModuleData.duration_type === "week"
                              }
                              value="week"
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Check
                              inline
                              name="duration_type"
                              label="Month"
                              type="radio"
                              checked={
                                accessModuleData.duration_type === "month"
                              }
                              value="month"
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Check
                              inline
                              name="duration_type"
                              label="Year"
                              type="radio"
                              checked={
                                accessModuleData.duration_type === "year"
                              }
                              value="year"
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Duration Type is required
                            </Form.Control.Feedback>
                          </div>
                        ) : (
                          <div className="fw-bold">
                            {accessModuleData.duration_type}
                          </div>
                        )}
                      </Form.Group>
                      {accessModuleData.access_module_features &&
                        accessModuleData.access_module_features
                          .is_feature_checkins && (
                          <Form.Group className="mb-3">
                            <Form.Label>Check-ins</Form.Label>
                            {accessModuleData.access_module_features &&
                            accessModuleData.access_module_features
                              .is_edit_checkins_maximum ? (
                              <>
                                <Form.Control
                                  type="number"
                                  name="checkins_maximum"
                                  value={accessModuleData.checkins_maximum}
                                  onChange={handleInputChange}
                                  placeholder="Enter checkins"
                                />
                                <Form.Text className="text-muted">
                                  Enter a maximum of 999 check-ins
                                </Form.Text>
                              </>
                            ) : (
                              <div className="fw-bold">
                                {accessModuleData.checkins_maximum}
                              </div>
                            )}
                          </Form.Group>
                        )}
                    </Form>
                  </Card.Body>
                </Card>
                {accessModuleData.access_module_features &&
                  accessModuleData.access_module_features
                    .is_feature_invitions && (
                    <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                      <Card.Header className="bg-secondary text-white">
                        Invitions
                      </Card.Header>
                      <Card.Body className="d-flex p-3">
                        <Form
                          noValidate
                          validated={validated}
                          className="w-100"
                        >
                          <Form.Check
                            className="mb-3" // prettier-ignore
                            type="switch"
                            checked={accessModuleData.invitation_is_enable}
                            name="invitation_is_enable"
                            label="Enable invitations"
                            onChange={handleInputChange}
                          />
                          {accessModuleData.invitation_is_enable && (
                            <>
                              {accessModuleData.access_module_features &&
                              accessModuleData.access_module_features
                                .is_edit_invitation_checkins_maximum ? (
                                <Form.Group className="mb-3">
                                  <Form.Label>Invitation maximum</Form.Label>
                                  <div>
                                    <Form.Check
                                      inline
                                      name="invitationLimitations"
                                      label="Limited"
                                      type="radio"
                                      checked={
                                        invitationLimitations === "limited"
                                      }
                                      value="limited"
                                      onChange={onChangeInvitationLimitations}
                                      required
                                    />
                                    <Form.Check
                                      inline
                                      name="invitationLimitations"
                                      label="Unlimited"
                                      type="radio"
                                      checked={
                                        invitationLimitations === "unlimited"
                                      }
                                      value="unlimited"
                                      onChange={onChangeInvitationLimitations}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Invitation maximum is required
                                    </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              ) : (
                                <Form.Group className="mb-3">
                                  <Form.Label>Invitation maximum</Form.Label>
                                  <div className="fw-bold">
                                    {accessModuleData.invitation_maximum}{" "}
                                    Maximum
                                  </div>
                                </Form.Group>
                              )}
                              {accessModuleData.access_module_features &&
                                accessModuleData.access_module_features
                                  .is_edit_checkins_maximu &&
                                invitationLimitations === "limited" && (
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="number"
                                      name="invitation_maximum"
                                      value={
                                        accessModuleData.invitation_maximum
                                      }
                                      onChange={handleInputChange}
                                      placeholder="Enter invitation maximum"
                                      required
                                    />
                                    <Form.Text className="text-muted">
                                      Enter a maximum of 999 invitations
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                      Invitation maximum is required
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                )}
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  Invitation Duration Type
                                </Form.Label>
                                {accessModuleData.access_module_features &&
                                accessModuleData.access_module_features
                                  .is_edit_invitation_duration_type ? (
                                  <div>
                                    <Form.Check
                                      inline
                                      name="invitation_duration_type"
                                      label="One Time"
                                      type="radio"
                                      checked={
                                        accessModuleData.invitation_duration_type ===
                                        "one_time"
                                      }
                                      value="one_time"
                                      onChange={handleInputChange}
                                      required
                                    />
                                    <Form.Check
                                      inline
                                      name="invitation_duration_type"
                                      label="Day"
                                      type="radio"
                                      checked={
                                        accessModuleData.invitation_duration_type ===
                                        "day"
                                      }
                                      value="day"
                                      onChange={handleInputChange}
                                      required
                                    />
                                    <Form.Check
                                      inline
                                      name="invitation_duration_type"
                                      label="Week"
                                      type="radio"
                                      checked={
                                        accessModuleData.invitation_duration_type ===
                                        "week"
                                      }
                                      value="week"
                                      onChange={handleInputChange}
                                      required
                                    />
                                    <Form.Check
                                      inline
                                      name="invitation_duration_type"
                                      label="Month"
                                      type="radio"
                                      checked={
                                        accessModuleData.invitation_duration_type ===
                                        "month"
                                      }
                                      value="month"
                                      onChange={handleInputChange}
                                      required
                                    />
                                    <Form.Check
                                      inline
                                      name="invitation_duration_type"
                                      label="Year"
                                      type="radio"
                                      checked={
                                        accessModuleData.invitation_duration_type ===
                                        "year"
                                      }
                                      value="year"
                                      onChange={handleInputChange}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Invitation Duration Type is required
                                    </Form.Control.Feedback>
                                  </div>
                                ) : (
                                  <div className="fw-bold">
                                    {accessModuleData.invitation_duration_type}
                                  </div>
                                )}
                              </Form.Group>
                              {accessModuleData.invitation_duration_type !==
                                "one_time" && (
                                <Form.Group className="mb-3">
                                  <Form.Label>Check-ins</Form.Label>
                                  {accessModuleData.access_module_features &&
                                  accessModuleData.access_module_features
                                    .is_edit_invitation_duration_type ? (
                                    <>
                                      <Form.Control
                                        type="number"
                                        name="invitation_checkins_maximum"
                                        value={
                                          accessModuleData.invitation_checkins_maximum
                                        }
                                        onChange={handleInputChange}
                                        placeholder="Enter checkins"
                                      />
                                      <Form.Text className="text-muted">
                                        Enter a maximum of 2 check-ins
                                      </Form.Text>
                                    </>
                                  ) : (
                                    <div className="fw-bold">
                                      {
                                        accessModuleData.invitation_checkins_maximum
                                      }{" "}
                                      Maximum
                                    </div>
                                  )}
                                </Form.Group>
                              )}
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  Invitation date start type
                                </Form.Label>
                                {accessModuleData.access_module_features &&
                                accessModuleData.access_module_features
                                  .is_edit_invitation_duration_type ? (
                                  <div className="d-flex">
                                    <div className="d-grid">
                                      <Form.Check
                                        inline
                                        name="invitation_date_start_type"
                                        label="Date start now"
                                        type="radio"
                                        checked={
                                          accessModuleData.invitation_date_start_type ===
                                          "date_start_now"
                                        }
                                        value="date_start_now"
                                        onChange={handleInputChange}
                                        required
                                      ></Form.Check>
                                      <Form.Text className="text-muted">
                                        Start date when sent invitation
                                      </Form.Text>
                                    </div>
                                    <div className="d-grid ms-5">
                                      <Form.Check
                                        inline
                                        name="invitation_date_start_type"
                                        label="Custom Date start"
                                        type="radio"
                                        checked={
                                          accessModuleData.invitation_date_start_type ===
                                          "custom_date_start"
                                        }
                                        value="custom_date_start"
                                        onChange={handleInputChange}
                                        required
                                      />
                                      <Form.Text className="text-muted">
                                        The access owner can specify the start
                                        date to invitation
                                      </Form.Text>
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                      Invitation date start type is required
                                    </Form.Control.Feedback>
                                  </div>
                                ) : (
                                  <div className="fw-bold">
                                    {
                                      accessModuleData.invitation_date_start_type
                                    }{" "}
                                  </div>
                                )}
                              </Form.Group>
                            </>
                          )}
                        </Form>
                      </Card.Body>
                    </Card>
                  )}
                {accessModuleData.access_module_features &&
                  accessModuleData.access_module_features
                    .is_feature_custom_fields && (
                    <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                      <Card.Header className="bg-secondary text-white">
                        Custom Fields
                      </Card.Header>
                      <Card.Body className="d-flex p-3">
                        <Form className="w-100">
                          <CustomFieldsCreate
                            customFieldData={customFieldData}
                            setCustomFieldData={setCustomFieldData}
                          />
                        </Form>
                      </Card.Body>
                    </Card>
                  )}
                <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                  <Card.Header className="bg-secondary text-white">
                    Select readers{" "}
                    {accessModuleData.reader_app_maximum &&
                      "(" + accessModuleData.reader_app_maximum + " Maximum)"}
                  </Card.Header>
                  <Card.Body className="d-flex p-3">
                    <Form className="w-100">
                      <HelparAlert
                        status="error"
                        message={readersCheckErrors}
                      ></HelparAlert>

                      {readersList && readersList.length > 0 ? (
                        <Form.Group className="mb-3">
                          <Row>
                            {readersList &&
                              readersList.map((item) => (
                                <Col key={item.id} className="mb-3 ms-1" md={3}>
                                  <Card>
                                    <Card.Body>
                                      <Card.Title>{item.name}</Card.Title>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                      <Form.Check
                                        inline
                                        type="checkbox"
                                        checked={
                                          readers.find((u) => u === item.id) ||
                                          false
                                        }
                                        value={item.id}
                                        onChange={handleCheckboxReaderChange}
                                      />
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              ))}
                          </Row>
                        </Form.Group>
                      ) : (
                        <Alert color="secondary" className="text-center">
                          <h5 className="mb-3">{message}</h5>{" "}
                          <Link
                            to={
                              "/business/projects/reader-app/create/" +
                              params.hash_id
                            }
                            className="btn btn-sm btn-light"
                          >
                            Create New Reader
                          </Link>
                        </Alert>
                      )}
                    </Form>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading…" : "submit"}
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
            <Col md={4}>
              <AccessCard
                description={accessModuleData.description}
                checkins_maximum={accessModuleData.checkins_maximum}
                reader_apps={readersChecked}
              />
            </Col>
          </Row>
        </Container>
      )}
    </LayoutProjects>
  );
};
export default AccessModuleUpdate;
