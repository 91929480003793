import ApiBase from './Base'

const url = "api/project/modules/"

export default {
    async list(logout,hash_id) {
        try {
            const response = await ApiBase.apiBase(url + 'list/' + hash_id, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
   
}
