import ApiBase from './Base'

const url = "api/general_structures/"

export default {
    async List(logout,hash_id,access_module,parent='') {
        try {
            const response = await ApiBase.apiBase(url + 'list/'+access_module+'/' + hash_id + '?parent=' + parent  , 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetById(logout,id, hash_id) {
        try {
            const urls = url + 'get_by_id/' + id + "/" + hash_id;
            const response = await ApiBase.apiBase(urls, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    
}
