/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import apiAccessModules from "../../Apis/AccessModules";
import CustomFieldsCreate from "../../CustomFields/Create";
import HelparAlert from "../../Helpars/Alert";
import { useAuth } from "../../AuthenticationUtility/AuthContext";

const ModeratorAccessModulesCreate = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [customFieldData, setCustomFieldData] = useState([]);
  const [fieldIndex, setFieldIndex] = useState(0);
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [checkinsMaximum, setCheckinsMaximum] = useState(null);
  const [expirationType, setExpirationType] = useState("");
  const [durationType, setDurationType] = useState(null);

  // Invitations
  const [isInvitionsEnable, setIsInvitionsEnable] = useState(false);
  const [invitationLimitations, setInvitationLimitations] = useState("");
  const [invitationMaximum, setInvitationMaximum] = useState(null);
  const [invitationDurationType, setInvitationDurationType] = useState("");
  const [invitationCheckinsMaximum, setInvitationCheckinsMaximum] =
    useState(null);
  const [invitationDatStartType, setInvitationDatStartType] = useState("");
  const [featureCheckIns, setFeatureCheckIns] = useState(false);
  const [featureInvitions, setFeatureInvitions] = useState(false);
  const [featureReaderApp, setFeatureReaderApp] = useState(false);
  const [featureCustomFields, setFeatureCustomFields] = useState(false);
  const [featureForm, setFeatureForm] = useState(false);
  const [featureSendAccessByCSV, setFeatureSendAccessByCSV] = useState(false);
  const [readerAppLimitations, setReaderAppLimitations] = useState(false);
  const [readerAppMaximum, setReaderAppMaximum] = useState("");
  const [formLimitations, setFormLimitations] = useState("");
  const [formMaximum, setFormMaximum] = useState(null);

  // Edit state toggles
  const [checkinsMaximumIsEdit, setCheckinsMaximumIsEdit] = useState(false);
  const [expirationTypeEdit, setExpirationTypeEdit] = useState(false);
  const [durationTypeEdit, setDurationTypeEdit] = useState(false);
  const [invitationLimitationsEdit, setInvitationLimitationsEdit] =
    useState(false);
  const [invitationDurationTypeEdit, setInvitationDurationTypeEdit] =
    useState(false);
  const [invitationCheckinsMaximumEdit, setInvitationCheckinsMaximumEdit] =
    useState(false);
  const [invitationDatStartTypeEdit, setInvitationDatStartTypeEdit] =
    useState(false);
  const [customFieldsEdit, setCustomFieldsEdit] = useState(false);

  // Input change handlers
  const onChangeDescription = (event) => {
    const maxCharacterCount = 150;
    if (event.target.value.length <= maxCharacterCount) {
      setDescription(event.target.value);
    }
  };

  const onChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const onChangeExpirationType = (event) => {
    setExpirationType(event.target.value);
  };

  const onChangeCheckinsMaximum = (event) => {
    const maxCharacterCount = 3;
    const value = Number(event.target.value);
    if (event.target.value.length <= maxCharacterCount && value >= 0) {
      setCheckinsMaximum(value);
    }
  };

  const onChangeDurationType = (event) => {
    setDurationType(event.target.value);
  };

  const onChangeFeatureCheckIns = () => {
    setFeatureCheckIns(!featureCheckIns);
  };

  const onChangeFeatureInvitions = () => {
    setFeatureInvitions(!featureInvitions);
  };

  const onChangeFeatureReaderApp = () => {
    setFeatureReaderApp(!featureReaderApp);
  };

  const onChangeFeatureCustomFields = () => {
    setFeatureCustomFields(!featureCustomFields);
  };

  const onChangeFeatureForm = () => {
    setFeatureForm(!featureForm);
  };

  const onChangeFeatureSendAccessByCSV = () => {
    setFeatureSendAccessByCSV(!featureSendAccessByCSV);
  };

  const onChangeReaderAppLimitations = (event) => {
    setReaderAppLimitations(event.target.value);
  };

  const onChangeReaderAppMaximum = (event) => {
    setReaderAppMaximum(event.target.value);
  };

  const onChangeFormLimitations = (event) => {
    setFormLimitations(event.target.value);
  };

  const onChangeFormMaximum = (event) => {
    setFormMaximum(event.target.value);
  };

  // Edit toggles
  const onChangeCheckinsMaximumIsEdit = () => {
    setCheckinsMaximumIsEdit(!checkinsMaximumIsEdit);
  };

  const onChangeExpirationTypeEdit = () => {
    setExpirationTypeEdit(!expirationTypeEdit);
  };

  const onChangeDurationTypeEdit = () => {
    setDurationTypeEdit(!durationTypeEdit);
  };

  const onChangeInvitationLimitationsEdit = () => {
    setInvitationLimitationsEdit(!invitationLimitationsEdit);
  };

  const onChangeInvitationDurationTypeEdit = () => {
    setInvitationDurationTypeEdit(!invitationDurationTypeEdit);
  };

  const onChangeInvitationCheckinsMaximumEdit = () => {
    setInvitationCheckinsMaximumEdit(!invitationCheckinsMaximumEdit);
  };

  const onChangeInvitationDatStartTypeEdit = () => {
    setInvitationDatStartTypeEdit(!invitationDatStartTypeEdit);
  };

  const onChangeCustomFieldsEdit = () => {
    setCustomFieldsEdit(!customFieldsEdit);
  };

  // Invitation settings
  const onChangeIsInvitionsEnable = () => {
    setIsInvitionsEnable((prevState) => {
      const newState = !prevState;
      if (newState === true) {
        setValidated(true);
      }
      return newState;
    });
  };

  const onChangeInvitationDurationType = (event) => {
    setInvitationDurationType(event.target.value);
  };

  const onChangeInvitationCheckinsMaximum = (event) => {
    const value = Number(event.target.value);
    if (value <= 2 && value > 0) {
      setInvitationCheckinsMaximum(value);
    }
  };

  const onChangeInvitationLimitations = (event) => {
    setInvitationLimitations(event.target.value);
  };

  const onChangeInvitationMaximum = (event) => {
    const maxCharacterCount = 3;
    const value = Number(event.target.value);
    if (event.target.value.length <= maxCharacterCount && value >= 0) {
      setInvitationMaximum(value);
    }
  };

  const onChangeInvitationDatStartType = (event) => {
    setInvitationDatStartType(event.target.value);
  };

  // Form submission handler
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let AccessModulesObj = {
        title: title,
        description: description,
        checkins_maximum: checkinsMaximum ? checkinsMaximum : null,
        expiration_type: expirationType,
        duration_type: durationType,
        serial_type: "dynamic_qr_code",
        invitation_is_enable: isInvitionsEnable,
        invitation_maximum:
          invitationLimitations === "limited" ? invitationMaximum : null,
        invitation_duration_type:invitationDurationType? invitationDurationType: null,
        invitation_date_start_type:invitationDatStartType? invitationDatStartType: null,
        invitation_checkins_maximum:invitationCheckinsMaximum? invitationCheckinsMaximum: null,
        custom_fields:customFieldData? customFieldData: null,
        reader_app_maximum:
          readerAppLimitations === "limited" ? readerAppMaximum : null,
        form_maximum: formLimitations === "limited" ? formMaximum : null,
        is_feature_checkins: featureCheckIns,
        is_feature_invitions: featureInvitions,
        is_feature_reader_app: featureReaderApp,
        is_feature_form: featureForm,
        is_feature_send_access_by_csv: featureSendAccessByCSV,
        is_feature_custom_fields: featureCustomFields,
        is_edit_checkins_maximum: checkinsMaximumIsEdit,
        is_edit_expiration_type: expirationTypeEdit,
        is_edit_duration_type: durationTypeEdit,
        is_edit_invitation_duration_type: invitationDurationTypeEdit,
        is_edit_invitation_checkins_maximum: invitationCheckinsMaximumEdit,
        is_edit_invitation_date_start_type: invitationDatStartTypeEdit,
        is_edit_custom_fields: customFieldsEdit,
      };

      let response = await apiAccessModules.Create(logout, AccessModulesObj);
      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);

      if (response.status === "success") {
        navigate(
          "/moderator/access-modules-packages/create/" + response.data.id
        );
      }
    }

    setValidated(true);
    setLoading(false);
  };

  return (
    <Form noValidate validated={validated} onSubmit={onSubmit}>
      <HelparAlert
        status={status}
        message={message}
        errors={errors}
      ></HelparAlert>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0 ">
        <Card.Header className="bg-secondary text-white">
          Access Module info
        </Card.Header>
        <Card.Body className="d-flex p-3">
          <Form className="w-100">
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title}
                onChange={onChangeTitle}
                placeholder="Enter title"
                required
              />
              <Form.Control.Feedback type="invalid">
                Title is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                onChange={onChangeDescription}
                placeholder="Enter descriptione"
                required
              />
              <Form.Text className="text-muted">
                Enter a maximum of 150 characters
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                Description is required
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Card.Header className="bg-secondary text-white">
          Access setting
        </Card.Header>
        <Card.Body className="d-flex p-3">
          <Form className="w-100">
            <Form.Group className="mb-3">
              <Form.Label>Expiration Type</Form.Label>
              <Row>
                <Col>
                  <div>
                    <Form.Check
                      inline
                      name="expirationType"
                      label="Date"
                      type="radio"
                      checked={expirationType === "date"}
                      value="date"
                      onChange={onChangeExpirationType}
                      required
                    />
                    <Form.Check
                      inline
                      name="expirationType"
                      label="Forever"
                      type="radio"
                      checked={expirationType === "for_ever"}
                      value="for_ever"
                      onChange={onChangeExpirationType}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Expiration Type is required
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col>
                  <Form.Check
                    inline
                    className="mb-2"
                    name="expirationTypeEdit"
                    label="Can Edit"
                    type="checkbox"
                    checked={expirationTypeEdit}
                    onChange={onChangeExpirationTypeEdit}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Duration Type</Form.Label>
              <Row>
                <Col>
                  <div>
                    <Form.Check
                      inline
                      name="durationType"
                      label="Custom"
                      type="radio"
                      checked={durationType === "custom"}
                      value="custom"
                      onChange={onChangeDurationType}
                      required
                    />
                    <Form.Check
                      inline
                      name="durationType"
                      label="Day"
                      type="radio"
                      checked={durationType === "day"}
                      value="day"
                      onChange={onChangeDurationType}
                      required
                    />
                    <Form.Check
                      inline
                      name="durationType"
                      label="Week"
                      type="radio"
                      checked={durationType === "week"}
                      value="week"
                      onChange={onChangeDurationType}
                      required
                    />
                    <Form.Check
                      inline
                      name="durationType"
                      label="Month"
                      type="radio"
                      checked={durationType === "month"}
                      value="month"
                      onChange={onChangeDurationType}
                      required
                    />
                    <Form.Check
                      inline
                      name="durationType"
                      label="Year"
                      type="radio"
                      checked={durationType === "year"}
                      value="year"
                      onChange={onChangeDurationType}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Duration Type is required
                    </Form.Control.Feedback>
                  </div>
                </Col>
                <Col>
                  <Form.Check
                    inline
                    className="mb-2"
                    name="durationTypeEdit"
                    label="Can Edit"
                    type="checkbox"
                    checked={durationTypeEdit}
                    onChange={onChangeDurationTypeEdit}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                className="mb-2"
                name="featureCheckIns"
                label="Check-ins"
                type="checkbox"
                checked={featureCheckIns}
                onChange={onChangeFeatureCheckIns}
              />
              {featureCheckIns && (
                <Row>
                  <Col>
                    <Form.Control
                      type="number"
                      value={checkinsMaximum}
                      onChange={onChangeCheckinsMaximum}
                      placeholder="Enter checkins maximum "
                    />
                    <Form.Text className="text-muted">
                      Enter a maximum of 999 check-ins
                    </Form.Text>
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      className="mb-2"
                      name="checkinsMaximumIsEdit"
                      label="Can Edit"
                      type="checkbox"
                      checked={checkinsMaximumIsEdit}
                      onChange={onChangeCheckinsMaximumIsEdit}
                    />
                  </Col>
                </Row>
              )}
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Form.Check
          className="mb-3" // prettier-ignore
          type="checkbox"
          checked={featureInvitions}
          label="Invitations"
          onChange={onChangeFeatureInvitions}
        />
        {featureInvitions && (
          <Card.Body className="d-flex p-3">
            <Form className="w-100">
              <Form.Check
                className="mb-3" // prettier-ignore
                type="checkbox"
                checked={isInvitionsEnable}
                label="Enable invitations"
                onChange={onChangeIsInvitionsEnable}
              />
              {isInvitionsEnable && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Invitation maximum</Form.Label>
                    <Row>
                      <Col>
                        <div>
                          <Form.Check
                            inline
                            name="invitationLimitations"
                            label="Limited"
                            type="radio"
                            checked={invitationLimitations === "limited"}
                            value="limited"
                            onChange={onChangeInvitationLimitations}
                            required
                          />
                          <Form.Check
                            inline
                            name="invitationLimitations"
                            label="Unlimited"
                            type="radio"
                            checked={invitationLimitations === "unlimited"}
                            value="unlimited"
                            onChange={onChangeInvitationLimitations}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Invitation maximum is required
                          </Form.Control.Feedback>
                        </div>
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          className="mb-2"
                          name="invitationLimitationsEdit"
                          label="Can Edit"
                          type="checkbox"
                          checked={invitationLimitationsEdit}
                          onChange={onChangeInvitationLimitationsEdit}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {invitationLimitations === "limited" && (
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="number"
                        value={invitationMaximum}
                        onChange={onChangeInvitationMaximum}
                        placeholder="Enter invitation maximum"
                        required
                      />
                      <Form.Text className="text-muted">
                        Enter a maximum of 999 invitations
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        Invitation maximum is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Invitation Duration Type</Form.Label>
                    <Row>
                      <Col>
                        <div>
                          <Form.Check
                            inline
                            name="invitationDurationType"
                            label="One Time"
                            type="radio"
                            checked={invitationDurationType === "one_time"}
                            value="one_time"
                            onChange={onChangeInvitationDurationType}
                            required
                          />
                          <Form.Check
                            inline
                            name="invitationDurationType"
                            label="Day"
                            type="radio"
                            checked={invitationDurationType === "day"}
                            value="day"
                            onChange={onChangeInvitationDurationType}
                            required
                          />
                          <Form.Check
                            inline
                            name="invitationDurationType"
                            label="Week"
                            type="radio"
                            checked={invitationDurationType === "week"}
                            value="week"
                            onChange={onChangeInvitationDurationType}
                            required
                          />
                          <Form.Check
                            inline
                            name="invitationDurationType"
                            label="Month"
                            type="radio"
                            checked={invitationDurationType === "month"}
                            value="month"
                            onChange={onChangeInvitationDurationType}
                            required
                          />
                          <Form.Check
                            inline
                            name="invitationDurationType"
                            label="Year"
                            type="radio"
                            checked={invitationDurationType === "year"}
                            value="year"
                            onChange={onChangeInvitationDurationType}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Invitation Duration Type is required
                          </Form.Control.Feedback>
                        </div>
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          className="mb-2"
                          name="invitationDurationTypeEdit"
                          label="Can Edit"
                          type="checkbox"
                          checked={invitationDurationTypeEdit}
                          onChange={onChangeInvitationDurationTypeEdit}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {invitationDurationType !== "one_time" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Check-ins</Form.Label>
                      <Row>
                        <Col>
                          <Form.Control
                            type="number"
                            value={invitationCheckinsMaximum}
                            onChange={onChangeInvitationCheckinsMaximum}
                            placeholder="Enter checkins"
                          />
                          <Form.Text className="text-muted">
                            Enter a maximum of 2 check-ins
                          </Form.Text>
                        </Col>
                        <Col>
                          <Form.Check
                            inline
                            className="mb-2"
                            name="invitationCheckinsMaximumEdit"
                            label="Can Edit"
                            type="checkbox"
                            checked={invitationCheckinsMaximumEdit}
                            onChange={onChangeInvitationCheckinsMaximumEdit}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Invitation date start type</Form.Label>
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <div className="d-grid">
                            <Form.Check
                              inline
                              name="invitationDatStartType"
                              label="Date start now"
                              type="radio"
                              checked={
                                invitationDatStartType === "date_start_now"
                              }
                              value="date_start_now"
                              onChange={onChangeInvitationDatStartType}
                              required
                            ></Form.Check>
                            <Form.Text className="text-muted">
                              Start date when sent invitation
                            </Form.Text>
                          </div>
                          <div className="d-grid ms-5">
                            <Form.Check
                              inline
                              name="invitationDatStartType"
                              label="Custom Date start"
                              type="radio"
                              checked={
                                invitationDatStartType === "custom_date_start"
                              }
                              value="custom_date_start"
                              onChange={onChangeInvitationDatStartType}
                              required
                            />
                            <Form.Text className="text-muted">
                              The access owner can specify the start date to
                              invitation
                            </Form.Text>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Invitation date start type is required
                          </Form.Control.Feedback>
                        </div>
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          className="mb-2"
                          name="invitationCheckinsMaximumEdit"
                          label="Can Edit"
                          type="checkbox"
                          checked={invitationDatStartTypeEdit}
                          onChange={onChangeInvitationDatStartTypeEdit}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
            </Form>
          </Card.Body>
        )}
      </Card>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Form.Check
          className="mb-3" // prettier-ignore
          type="checkbox"
          checked={featureReaderApp}
          label="Reader App"
          onChange={onChangeFeatureReaderApp}
        />
        {featureReaderApp && (
          <Card.Body className="d-flex p-3">
            <Form className="w-100">
              <Form.Group className="mb-3">
                <Form.Label>Reader app maximum</Form.Label>
                <div>
                  <Form.Check
                    inline
                    name="readerAppLimitations"
                    label="Limited"
                    type="radio"
                    checked={readerAppLimitations === "limited"}
                    value="limited"
                    onChange={onChangeReaderAppLimitations}
                    required
                  />
                  <Form.Check
                    inline
                    name="readerAppLimitations"
                    label="Unlimited"
                    type="radio"
                    checked={readerAppLimitations === "unlimited"}
                    value="unlimited"
                    onChange={onChangeReaderAppLimitations}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Reader app maximum is required
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              {readerAppLimitations === "limited" && (
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    value={readerAppMaximum}
                    onChange={onChangeReaderAppMaximum}
                    placeholder="Enter reader maximum"
                    required
                  />
                  <Form.Text className="text-muted">
                    Enter a maximum of 999 invitations
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Reader App maximum is required
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Form>
          </Card.Body>
        )}
      </Card>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Form.Check
          className="mb-3" // prettier-ignore
          type="checkbox"
          checked={featureCustomFields}
          label="Custom Fields"
          onChange={onChangeFeatureCustomFields}
        />
        {featureCustomFields && (
          <Card.Body className="d-flex p-3">
            <Form className="w-100">
              <Form.Check
                inline
                className="mb-2"
                name="customFieldEdit"
                label="Can Edit"
                type="checkbox"
                checked={customFieldsEdit}
                onChange={onChangeCustomFieldsEdit}
              />
              <CustomFieldsCreate
                customFieldData={customFieldData}
                setCustomFieldData={setCustomFieldData}
                fieldIndex={fieldIndex}
                setFieldIndex={setFieldIndex}
              />
            </Form>
          </Card.Body>
        )}
      </Card>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Form.Check
          className="mb-3" // prettier-ignore
          type="checkbox"
          checked={featureSendAccessByCSV}
          label="Send Access By CSV"
          onChange={onChangeFeatureSendAccessByCSV}
        />
      </Card>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Form.Check
          className="mb-3" // prettier-ignore
          type="checkbox"
          checked={featureForm}
          label="Forms"
          onChange={onChangeFeatureForm}
        />
        {featureForm && (
          <Card.Body className="d-flex p-3">
            <Form className="w-100">
              <Form.Group className="mb-3">
                <Form.Label>Form maximum</Form.Label>
                <div>
                  <Form.Check
                    inline
                    name="formLimitations"
                    label="Limited"
                    type="radio"
                    checked={formLimitations === "limited"}
                    value="limited"
                    onChange={onChangeFormLimitations}
                    required
                  />
                  <Form.Check
                    inline
                    name="formLimitations"
                    label="Unlimited"
                    type="radio"
                    checked={formLimitations === "unlimited"}
                    value="unlimited"
                    onChange={onChangeFormLimitations}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Form maximum is required
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              {formLimitations === "limited" && (
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    value={formMaximum}
                    onChange={onChangeFormMaximum}
                    placeholder="Enter form maximum"
                    required
                  />
                  <Form.Text className="text-muted">
                    Enter a maximum of 999 invitations
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Form maximum is required
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Form>
          </Card.Body>
        )}
      </Card>
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Card.Footer className="text-muted">
          <Button
            type="submit"
            variant="primary"
            size="md"
            disabled={isLoading}
          >
            {isLoading ? "Loading…" : "submit and add price"}
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};
export default ModeratorAccessModulesCreate;
