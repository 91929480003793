/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import qrScanner from "../assets/img/qr-scanner.svg";

const LandingReaderApp = () => {
  return (
    <section id="ReaderApp" class="pt-md-11 mt-4 py-5 bg-primary">
      <div class="container">
        <div className="row">
          <div className="d-flex col-md-6">
            <div class="align-items-center align-content-center align-self-center text-center">
              <h1 className="fs-2 fw-bold text-white mb-5">KNTO Reader</h1>
              <p class="lead text-white mb-6 mb-lg-8 fs-3">
                Streamlined Access for a Seamless User Experience
              </p>
            </div>
          </div>
          <div className="col-md-6 text-center">
            {/* <img src={qrScanner} width="300" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};
export default LandingReaderApp;
