import React, { createContext, useState, useContext, useEffect } from "react";
import {
  setToken,
  getToken,
  removeToken,
} from "../AuthenticationUtility/AuthHelper";
import ApiBusinesses from "../Apis/Businesses";

import apiUser from "../Apis/Users";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getToken());
  const [userData, setUserData] = useState(null);
  const [isModerator, seIsModerator] = useState(false);
  const [business, setBusiness] = useState(null);


  const login = (token, refreshToken, remember) => {
    setToken(token, refreshToken, remember);
    setIsLoggedIn(true);
  };
  useEffect(() => {
    if (isLoggedIn) {
      getUser(); // Fetch user data when the page loads if logged in
      getBussines();
    }
  }, [isLoggedIn]);
  const logout = (navigate) => {
    removeToken();
    setIsLoggedIn(false);
    navigate("/account/login");
  };
  const getUser = async () => {
    try {
      let response = await apiUser.Get(logout);
      if (response.status==="success") {
        setUserData(response.data.user);
        seIsModerator(response.data.is_moderator);
      }
      else
      {
        logout() ;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      logout() ;
    }
  };
  const getBussines = async (navigate) => {
    try {
      const businessData = await ApiBusinesses.Get(logout);
      if (!businessData.data) {
        navigate("/business/request");
      }
      setBusiness(businessData.data);
    } catch (error) {
      console.error("Error fetching bussines data:", error);
    }
  };
  const value = {
    isLoggedIn,
    userData,
    isModerator,
    login,
    logout,
    getUser,
    business,
    getBussines,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
